import React, { useState, useEffect } from 'react';
import { Box, LinearProgress } from '@mui/material';

const LoadingBar = ({ avgSecondsToFinish, isComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now(); // Capture the start time

    if (isComplete) {
      setProgress(100); // Jump to 100% when the request is complete
      return;
    }

    const interval = setInterval(() => {
      setProgress(() => {
        const elapsedSeconds = (Date.now() - startTime) / 1000; // Calculate elapsed time in seconds
        const calculatedProgress = Math.min((elapsedSeconds / avgSecondsToFinish) * 100, 99); // Cap at 99% until complete
        return calculatedProgress;
      });
    }, 100); // Update every 100ms for smooth progress

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [avgSecondsToFinish, isComplete]);

  return (
    <Box sx={{ width: '100%', textAlign: 'center', mt: 4 }}>
      <LinearProgress
        style={{ height: '10px' }}
        variant="determinate"
        value={isComplete ? 100 : progress} // Set to 100% when complete
      />
    </Box>
  );
};

export default LoadingBar;