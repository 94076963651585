import React, { useState, useEffect } from 'react';
import axios from '../services/api';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Grid,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    CircularProgress,
    Select,
    FormControl,
    InputLabel,
    Button,
    Checkbox,
    ListItemText,
    FormControlLabel,
    Skeleton, Alert, Snackbar
} from '@mui/material';
import { styled } from '@mui/system';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { ResponsiveContainer } from "recharts";
import LoadingBar from "./LoadingBar";

const Container = styled(Box)(({ theme }) => ({
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const TableWrapper = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(4),
    overflowX: 'auto',
    [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
    },
}));

const StyledTable = styled(Table)(({ theme }) => ({
    width: '100%',
    tableLayout: 'auto',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: '#f5f5f5',
    cursor: 'pointer',
}));

const Summary = () => {
    const { t } = useTranslation();
    const [summary, setSummary] = useState([]);
    const [year, setYear] = useState(localStorage.getItem('year') || '2024');
    const [month, setMonth] = useState(localStorage.getItem('month') || '05');
    const [city, setCity] = useState(localStorage.getItem('city') || 'Lisbon');
    const [view, setView] = useState(localStorage.getItem('view') || 'warehouse');
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [allWarehousesSelected, setAllWarehousesSelected] = useState(true);
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [orderDirection, setOrderDirection] = useState('desc');
    const [moreThan, setMoreThan] = useState('');
    const [nameFilter, setNameFilter] = useState('');

    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const response = await axios.get('/storage/warehouses');
                setWarehouses(response.data);

                // Select all warehouses by default
                setSelectedWarehouses(response.data);
            } catch (error) {
                console.error('Failed to fetch warehouses', error);
            }
        };
        fetchWarehouses();
    }, []);

    useEffect(() => {
        localStorage.setItem('year', year);
        localStorage.setItem('month', month);
        localStorage.setItem('city', city);
        localStorage.setItem('view', view);
    }, [year, month, city, view]);

    const fetchSummary = async () => {
        setLoading(true);
        setMoreThan('');
        setNameFilter('');
        try {
            const warehousesParam = selectedWarehouses.join(','); // Convert array to comma-separated string
            const response = await axios.get('/summary', {
                params: {
                    year,
                    month,
                    city,
                    view,
                    warehouses: warehousesParam, // Send warehouses as a comma-separated string
                }
            });
            setSummary(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleResetCache = async (isPeriod) => {
        try {
            const warehousesParam = selectedWarehouses.join(','); // Convert array to comma-separated string
            await axios.get('/comparison/reset-cache',isPeriod ? {
                params: {
                    year,
                    month,
                    warehouses: warehousesParam, // Send warehouses as a comma-separated string
                }
            } : {});
            setAlert({ open: true, message: t('cacheReseted'), severity: 'success' });
            fetchSummary();
        } catch (error) {
            console.error('Failed to reset cache', error);
        }
    }

    const getProductNames = (summary) => {
        const productNames = new Set();
        summary.forEach((item) => {
            Object.keys(item.productQuantities).forEach((product) => {
                productNames.add(product);
            });
        });
        return Array.from(productNames).sort();
    };

    const getTotalByProduct = (summary, product) => {
        return summary.reduce((total, item) => total + (item.productQuantities[product] || 0), 0);
    };

    const getTotalByWarehouseOrPharmacyOrZipCode = (item) => {
        return Object.values(item.productQuantities).reduce((total, quantity) => total + quantity, 0);
    };

    const handleSort = (column) => {
        const isAsc = orderBy === column && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(column);
        const sortedSummary = [...summary].sort((a, b) => {
            const aValue = column === 'total' ? getTotalByWarehouseOrPharmacyOrZipCode(a) : a.productQuantities[column] || 0;
            const bValue = column === 'total' ? getTotalByWarehouseOrPharmacyOrZipCode(b) : b.productQuantities[column] || 0;
            return (isAsc ? aValue - bValue : bValue - aValue);
        });
        setSummary(sortedSummary);
    };

    const handleWarehouseChange = (e) => {
        const { value, checked } = e.target;
        setSelectedWarehouses((prevSelected) =>
          checked ? [...prevSelected, value] : prevSelected.filter((w) => w !== value)
        );
    };

    const handleSelectAllWarehouses = (e) => {
        const { checked } = e.target;
        setAllWarehousesSelected(checked);
        setSelectedWarehouses(checked ? warehouses : []);
    };

    const productNames = getProductNames(summary);

    const productSalesData = {
        labels: productNames,
        datasets: [
            {
                label: t('total'),
                data: productNames.map(productName => getTotalByProduct(summary, productName)),
                backgroundColor: '#8884d8',
            },
        ],
    };

    const warehouseData = {
        labels: summary.map(item => item.viewName),
        datasets: [
            {
                label: t('total'),
                data: summary.map(item => getTotalByWarehouseOrPharmacyOrZipCode(item)),
                backgroundColor: '#82ca9d',
            },
        ],
    };

    const topCitiesData = {
        labels: summary
          .filter(item => item.viewName)
          .sort((a, b) => getTotalByWarehouseOrPharmacyOrZipCode(b) - getTotalByWarehouseOrPharmacyOrZipCode(a))
          .slice(0, 10)
          .map(item => item.viewName),
        datasets: [
            {
                label: t('total'),
                data: summary
                  .filter(item => item.viewName)
                  .sort((a, b) => getTotalByWarehouseOrPharmacyOrZipCode(b) - getTotalByWarehouseOrPharmacyOrZipCode(a))
                  .slice(0, 10)
                  .map(item => getTotalByWarehouseOrPharmacyOrZipCode(item)),
                backgroundColor: '#8884d8',
            },
        ],
    };

    const handleMoreThanChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Only accept numbers
            setMoreThan(value);
        }
    };

    const handleNameFilterChange = (e) => {
        setNameFilter(e.target.value);
    };

    // Filtering logic for both the "More than" and name filters
    const filteredSummary = summary.filter(item =>
      (!moreThan || getTotalByWarehouseOrPharmacyOrZipCode(item) >= Number(moreThan)) &&
      (!nameFilter || item.viewName.toLowerCase().includes(nameFilter.toLowerCase()))
    );

    return (
      <Container>
          <Typography variant="h4" gutterBottom>
              {t('salesSummary')}
          </Typography>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('year')}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                  >
                      {[2022, 2023, 2024, 2025].map((yearOption) => (
                        <MenuItem key={yearOption} value={yearOption}>
                            {yearOption}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('month')}
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                  >
                      {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(
                        (monthOption) => (
                          <MenuItem key={monthOption} value={monthOption}>
                              {monthOption}
                          </MenuItem>
                        )
                      )}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('city')}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                  >
                      {['Lisbon', 'Porto'].map((cityOption) => (
                        <MenuItem key={cityOption} value={cityOption}>
                            {cityOption}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel>{t('view')}</InputLabel>
                      <Select
                        value={view}
                        onChange={(e) => setView(e.target.value)}
                        label={t('view')}
                        disabled={loading}
                      >
                          <MenuItem value="warehouse">{t('warehouse')}</MenuItem>
                          <MenuItem value="pharmacy">{t('pharmacy')}</MenuItem>
                          <MenuItem value="city">{t('city')}</MenuItem>
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={8} sm={6} md={3}>
                  <FormControl fullWidth>
                      <InputLabel>{t('warehouse')}</InputLabel>
                      <Select
                        multiple
                        value={selectedWarehouses}
                        onChange={handleWarehouseChange}
                        renderValue={(selected) => selected.join(', ')}
                        disabled={loading}
                      >
                          {warehouses.map((warehouse) => (
                            <MenuItem key={warehouse} value={warehouse}>
                                <Checkbox
                                  checked={selectedWarehouses.includes(warehouse)}
                                  value={warehouse} // Set the value to the warehouse name
                                  onChange={handleWarehouseChange}
                                />
                                <ListItemText primary={warehouse} />
                            </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={3} sm={4} md={3}>
                  <FormControlLabel
                    control={
                        <Checkbox
                          checked={allWarehousesSelected}
                          onChange={handleSelectAllWarehouses}
                        />
                    }
                    label={t('selectAllWarehouses')}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchSummary}
                    disabled={loading}
                    fullWidth
                  >
                      {t('analyze')}
                  </Button>
              </Grid>


              {summary.length > 0 && (
                <>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                          //label={t('nameOf')}
                          value={nameFilter}
                          onChange={handleNameFilterChange}
                          variant="outlined"
                          fullWidth
                          disabled={loading || summary.length === 0}
                          placeholder={t('filterByName')}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextField
                          //label={t('moreThan')}
                          value={moreThan}
                          onChange={handleMoreThanChange}
                          variant="outlined"
                          fullWidth
                          disabled={loading || summary.length === 0}
                          placeholder={t('sales')}
                          inputMode="numeric"
                          type="tel"
                        />
                    </Grid>
                </>
              )}
          </Grid>

          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center">
                <LoadingBar avgSecondsToFinish={10} isComplete={!loading}/>
            </Box>
          )}

          {summary.length === 0 ? (
            <></>
          ): (
            <>
                <TableWrapper>
                    <TableContainer component={Paper}>
                        <StyledTable>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>{view === 'warehouse' ? t('warehouse') : view === 'pharmacy' ? t('pharmacy') : t('city')}</StyledTableCell>
                                    {productNames.map((productName) => (
                                      <StyledTableCell key={productName} onClick={() => handleSort(productName)}>
                                          {productName}
                                          {orderBy === productName ? (orderDirection === 'asc' ? ' ↑' : ' ↓') : ''}
                                      </StyledTableCell>
                                    ))}
                                    <StyledTableCell onClick={() => handleSort('total')}>
                                        {t('total')}
                                        {orderBy === 'total' ? (orderDirection === 'asc' ? ' ↑' : ' ↓') : ''}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredSummary.map((item, index) => (
                                  <TableRow key={index}>
                                      <StyledTableCell>{item.viewName}</StyledTableCell>
                                      {productNames.map((productName) => (
                                        <TableCell key={productName}>
                                            {item.productQuantities[productName] || 0}
                                        </TableCell>
                                      ))}
                                      <StyledTableCell>{getTotalByWarehouseOrPharmacyOrZipCode(item)}</StyledTableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                    <StyledTableCell>{t('total')}</StyledTableCell>
                                    {productNames.map((productName) => (
                                      <TableCell key={productName}>
                                          {getTotalByProduct(filteredSummary, productName)}
                                      </TableCell>
                                    ))}
                                    <StyledTableCell>
                                        {filteredSummary.reduce((total, item) => total + getTotalByWarehouseOrPharmacyOrZipCode(item), 0)}
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </StyledTable>
                    </TableContainer>
                </TableWrapper>

                {!loading && view === 'warehouse' && (
                  <>
                      <Box mt={4}>
                          <Typography variant="h5" gutterBottom>
                              {t('productSales')}
                          </Typography>
                          <ResponsiveContainer width="100%" height={300}>
                              <Bar data={productSalesData} options={{
                                  responsive: true,
                                  plugins: {
                                      legend: {
                                          position: 'top',
                                      },
                                      title: {
                                          display: true,
                                          text: t('productSales'),
                                      },
                                  },
                              }} />
                          </ResponsiveContainer>
                      </Box>
                      <Box mt={4}>
                          <Typography variant="h5" gutterBottom>
                              {t('warehouseSales')}
                          </Typography>
                          <ResponsiveContainer width="100%" height={300}>
                              <Bar data={warehouseData} options={{
                                  responsive: true,
                                  plugins: {
                                      legend: {
                                          position: 'top',
                                      },
                                      title: {
                                          display: true,
                                          text: t('warehouseSales'),
                                      },
                                  },
                              }} />
                          </ResponsiveContainer>
                      </Box>
                  </>
                )}
                {!loading && view === 'city' && (
                  <>
                      <Box mt={4}>
                          <Typography variant="h5" gutterBottom>
                              {t('topCities')}
                          </Typography>
                          <ResponsiveContainer width="100%" height={300}>
                              <Bar data={topCitiesData} options={{
                                  responsive: true,
                                  plugins: {
                                      legend: {
                                          position: 'top',
                                      },
                                      title: {
                                          display: true,
                                          text: t('topCities'),
                                      },
                                  },
                              }} />
                          </ResponsiveContainer>
                      </Box>
                  </>
                )}
                {(summary.length > 0 && !loading) && (
                  <>
                      <p style={{textAlign: "center", fontSize: "12px"}}>{t('cacheResetMessage')}</p>
                      <Grid container spacing={1} alignItems="center">
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{fontSize: "11px", marginLeft: "auto", marginRight: "auto", display: "block"}}
                            onClick={() => handleResetCache(true)}>
                              {t('resetSelectedPeriod')} - {month}/{year}
                          </Button>
                          <Button
                            variant="outlined"
                            color="warning"
                            style={{fontSize: "11px", marginLeft: "auto", marginRight: "auto", display: "block"}}
                            onClick={() => handleResetCache(false)}>
                              {t('hardReset')}
                          </Button>
                      </Grid>
                  </>
                )}
                <Snackbar
                  open={alert.open}
                  autoHideDuration={6000}
                  onClose={() => setAlert({ ...alert, open: false })}
                >
                    <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            </>
          )}
      </Container>
    );
};

export default Summary;